@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
    .days {
        gap: 12px;
        .padding-day,
        .month-day {
            justify-self: center;
        }
    }
    .month-day {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        padding: 7px;
        &.selected {
            background-color: var(--primary-color);
            color: var(--secondary-bg-color);
        }
    }
}
