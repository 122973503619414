.presence-type {
  color: var(--scroll-down-text-color);

  .options-container {
    display: grid;
    gap: 10px;
    height: fit-content;

    .options {
      width: fit-content;
      border-radius: 10px;
      overflow: hidden;

      .btn {
        border-radius: 0;
      }
    }
  }
}
