.lieu,
.raison {
  margin-top: 15px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;

  .lieu-name {
    color: var(--primary-color);
  }

  .raison-name {
    color: var(--absence-color);
  }

  .lieu-name,
  .raison-name {
    font-weight: bold;
    margin-bottom: 5px;

    .lieu-count,
    .raison-count {
      margin-left: 5px;
    }

    span {
      margin-left: 10px;
    }
  }

  .users {
    margin-left: 15px;
  }
}