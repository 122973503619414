.select-month {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .arrow-icon{
    color:var(--primary-text-color)
  }

  .month {
    text-transform: uppercase;
    font-weight: bold;
    width: 150px;
  }
}

