.saisie {
  .saisie-content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 20px;
    justify-content: center;
    
    .mode-create-delete{
      display: flex;
      flex-direction: column;
      
    }

    .mode-create.mobile{
      margin-top: 10px;
    }
  }
}
