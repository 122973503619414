$inputHeight: 36px;

input[type="text"]:not([role="combobox"]),
input[type="password"],
input[type="number"],
input[type="email"],
input[type="search"],
select {
  background-color: var(--input-bg-color);
  -webkit-appearance: none;
  transition-duration: 0.15s;
  transition-property: border, background-color, color, box-shadow;
  transition-timing-function: ease-in;
  border-radius: 0.25rem;
  padding: 0 7px;
  box-sizing: border-box;
  font-weight: normal;
  font-size: inherit;
  font-family: inherit;
  height: $inputHeight;
  border: 1px solid var(--primary-border-color);
  outline: none;

  &.full-width {
    width: 100%;
  }
  &.ng-invalid.ng-touched {
    border-color: var(--btn-danger-background);
  }
  &:focus {
    border-color: var(--input-active-border-color);
  }
  &:disabled {
    background-color: var(--input-disabled-bg-color);
  }
}

.input-with-icon {
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    opacity: 0.7;
  }

  input[type="text"]:not([role="combobox"]),
  input[type="password"],
  input[type="number"],
  input[type="email"] {
    padding-left: 35px;
  }
}

.input-caption {
  position: relative;

  input[type="text"]:not([role="combobox"]),
  input[type="password"],
  input[type="number"] {
    width: 100%;

    &.input-position {
      padding-right: 20px;
    }

    &.input-unite {
      padding-right: 35px;
    }

    &[type="number"] {
      appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }

  .caption {
    position: absolute;
    margin: 0;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 11px;
  }
}

label, .label {
  display: block;
  font-size: 11px;
  font-weight: bold;
  margin: 3px 0;
  color: var(--label-color);
}

::placeholder {
  opacity: 0.7;
}
