.saisie-mois {
  width: 100%;
  max-width: 700px;

  .calendar-container {
    width: 100%;

    .calendar {
      display: grid;
      margin-top: 10px;
      grid-template-columns: 50px 1fr;
      grid-template-areas:
        ". weekDays"
        "weeks days";

      .day-names {
        grid-area: weekDays;
      }
      .weeks {
        grid-area: weeks;
      }
      .days {
        grid-area: days;
      }
    }

    .day-names,
    .days {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 5px;
      padding: 5px;
      border-radius: 5px;

      > * {
        box-sizing: border-box;
      }

      .day-name {
        text-align: center;
        padding: 5px;
      }
    }

    .day-name {
      margin-bottom: 5px;
      font-weight: bold;
    }

    .days {
      background-color: var(--primary-bg-color);
      grid-auto-rows: 75px;

      .day {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        overflow: hidden;
        height: 100%;
        position: relative;

        .hidden{
          display: none !important;
        }

        &:not(.padding-day) {
          background-color: var(--secondary-bg-color);
        }

        .date-number {
          position: absolute;
          z-index: 1;
          top: 2px;
          left: 2px;
          transition: color 0.2s;
          font-size: 11px;

          &.inverse {
            color: var(--inverse-text-color);
          }
        }

        .full-day {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: var(--btn-tertiary-background);

          &:hover{
            background-color: var(--btn-secondary-background);
          }
        }


        .half-day {
          flex: 1;
          border-radius: 0;
          display: inline-flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
          color: inherit;
          color: var(--inverse-text-color);

          &:hover {
            background-color: var(--primary-bg-color);
          }

          &.present {
            background-color: var(--presence-color);
          }
          &.absent {
            background-color: var(--absence-color);
          }
        }
      }
    }

    .weeks {
      display: grid;
      grid-auto-rows: 75px;
      gap: 5px;
      padding: 5px;

      .week {
        font-weight: bold;
      }
    }
  }
}
