.login {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    padding: 30px;
    background-color: var(--primary-bg-color);
    border-radius: 10px;
    text-align: center;

    .login-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      min-width: 500px;

      

      div {
        padding-left: 30px;
        margin-right: 30px;
        width: 100%;
        &:not(:first-of-type){
          border-left: 1px solid var(--secondary-border-color);
        }
      }
      .form-container {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        justify-content: center;


        > * {
          margin: 0;
        }
      }

      .name-input {
        .full-width {
          margin-bottom: 25px;
        }
      }
    }

    .image-container {
      display: flex;
      justify-content: center;

      .image {
        width: 200px;
      }
    }
  }
}




