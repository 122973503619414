@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
    .saisie {
        .saisie-content {
            display:flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 20px;

            .mode-create-delete {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
