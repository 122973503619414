@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .week-view {
    display: flex;
    max-height: 70vh;
    min-height: 0px;
    overflow: hidden;

    .scroll-week-view{
      flex: 1 1 auto;
      overflow-y: scroll;
      grid-auto-flow: row;
    }
  }
}
