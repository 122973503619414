.tooltip-container {
  position: relative;

  &:hover .tooltip {
    display: block;
  }

  &.left .tooltip {
    left: auto;
    right: 100%;
  }

  &.top .tooltip {
    top: auto;
    bottom: 100%;
  }

  .tooltip {
    position: absolute;
    display: none;
    left: 100%;
    top: 100%;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: var(--secondary-bg-color);
    color: var(--primary-color);
    font-size: 14px;
    border-radius: 5px;
    z-index: 10;
    white-space: nowrap;
    max-width: 200px;
  }
}
