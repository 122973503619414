.toggle-main-title {
  font-weight: bold;
  display: flex;
  cursor: pointer;

  .title-name {
    text-decoration: underline;
    // position: fixed;
    margin-left: 15px;
  }

}

.presences {
    .title-name {
    color: var(--primary-color);
  }
}

.absences {
  margin-top: 10px;
  .title-name {
    color: var(--absence-color);
  }
}

.fa-chevron-down {
  transition-duration: 250ms;
}

.rotate90-anti-clockwise {
  transform: rotate(-90deg);
}