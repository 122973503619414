.slide-panel-container {
  &.open {
    .overlay {
      display: block;
    }
    .slide-panel {
      transform: translateX(0);
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 19;
    display: none;
  }

  .slide-panel {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--secondary-bg-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    transform: translateX(100%);
    transition: transform 0.3s;
  }
}
