.week-view {
  border-radius: 5px;
  background-color: var(--primary-bg-color);
  padding: 5px;

  min-height: 500px;
  .scroll-week-view {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 5px;

    min-height: 500px;
  }
}
