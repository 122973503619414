.presences-filters {
  padding: 10px;
  width: 300px;

  .filters-header {
    border-bottom: solid 1px var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 5px;

    .title {
      margin: 0;
    }

    .close-button {
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .filters {
    display: grid;
    margin-top: 20px;
    gap: 20px;
    color: var(--scroll-down-text-color);

    .absence-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      .present,
      .absent {
        display: block;
      }

      .present {
        color: var(--presence-color);
      }

      .absent {
        color: var(--absence-color);
      }
    }
  }

  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}