.loading-icon {
  text-align: center;
  margin: 10px 0;

  .icon {
    font-size: 20px;
    animation: spin linear infinite 1s;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
