.header {
  background: var(--color-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  position: sticky;
  top: 0;
  z-index: 10;

  .left {
    color: var(--inverse-text-color);
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    align-items: baseline;

    .version {
      font-size: 11px;
      opacity: 0.9;
    }
  }

  .change-theme {
    display: flex;
    align-items: center;
    width: 120px;
    font-size: 28px;
    justify-content: space-between;
    cursor: pointer;

    .theme-icon {
      color: var(--icon-theme-switch);
    }

    .cbx:checked~label {
      background: var(--slide-bar);
    }

    .cbx:checked~label:after {
      left: 20px;
    }

    .hidden {
      display: none;
    }

    .switch {
      position: relative;
      display: block;
      height: 20px;
      width: 44px;
      background: var(--slide-bar);
      border-radius: 100px;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .switch:after {
      position: absolute;
      left: -2px;
      top: -3px;
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 100px;
      background: var(--big-button);
      content: '';
      transition: all 0.3s ease;
    }

    .switch:active:after {
      transform: scale(1.15, 0.85);
    }
  }

  .brand {
    font-size: 30px;
    display: grid;
    align-items: baseline;
    grid-auto-flow: column;
    gap: 10px;
    font-weight: bold;

    .brand-logo {
      align-self: center;
      height: 42px;
    }

    .exclamation {
      color: var(--accent-color);
      transform: rotate(10deg);
      font-size: 35px;
    }
  }

  .right {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
    color: var(--inverse-text-color);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .header {
    padding: 5px 5px;

    .left {
      color: var(--inverse-text-color);
      display: grid;
      gap: 5px;
      grid-auto-flow: column;
      align-items: baseline;

      .version {
        font-size: 11px;
        opacity: 0.9;
      }
    }

    .brand {
      font-size: 20px;
      align-items: center;
      gap: 5px;
    }

    .right {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      align-items: center;
      color: var(--inverse-text-color);

      .user-name {
        width: min-content;
      }
    }
  }
}