@import 'colors';

 body {
  --color-header: #{$apolloBlue};
  --primary-color: #{$apolloBlue};
  --accent-color: #{$apolloRed};

  // background
  --primary-bg-color: #{$greyLighter};
  --secondary-bg-color: #{$white};
  --day-passed-bg-color: #{$whiteTransparent};
  --tertiary-bg-color: #{$greyInputBorder};
  --quaternary-bg-color: #{$grey};

  // text
  --primary-text-color: #{$greyDarker};
  --secondary-text-color: #{$greyMiddle};
  --inverse-text-color: #{$white};
  --scroll-down-text-color: #{$greyDarker};

  // titles
  --title-color: #{$apolloBlue};

  // borders
  --primary-border-color: #{$greyLightBlue};
  --secondary-border-color: #{$greyInputBorder};

  // buttons
  --btn-default-background: #{$white};
  --btn-default-color: #{$greyDarker};
  --btn-primary-background: #{$apolloBlue};
  --btn-secondary-background: #{$white};
  --btn-tertiary-background: #{$greyMiddleLight};
  --btn-danger-background: #{$apolloRed};
  --btn-success-background: #{$green};
  --btn-disabled-background: #{$greyLightest};
  --btn-disabled-color: #{$grey};
  --btn-no-style: #{$grey};

  // inputs
  --input-bg-color: #{$white};
  --input-disabled-bg-color: #{$greyLightBlue};
  --input-active-bg-color: #{$greyLightest};
  --input-border-color: #{$greyInputBorder};
  --input-hover-border-color: #{$grey};
  --input-active-border-color: #{$blue};
  --label-color: #{$greyMiddle};
  --today-active-color: #{$blue-extra-light};
  --holiday-bg-color: #{$greyLight};
  --presence-text-color: #{$white};

  // presence, absence
  --presence-color: #{$blue-middle};
  --absence-color: #{$red};

  // change theme
  --icon-theme-switch: #{$grey};
  --big-button: #{$grey};
  --slide-bar: #{$greyMiddle};
}