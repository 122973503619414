@import 'colors';

body[data-theme='dark'] {
  --primary-color: #{$blue-light};

  // background
  --primary-bg-color: #{$greyMiddle};
  --secondary-bg-color: #{$greyDarker};
  --day-passed-bg-color: #{$greyDarkerTransparent};

  // text
  --primary-text-color: #{$white};

  // buttons
  --btn-default-background: #{$greyDark};
  --btn-default-color: #{$greyLightest};
  --btn-secondary-background: #{$greyDark};
  --btn-tertiary-background: #{$greyLightDark};
  --btn-disabled-background: #{$grey};
  --btn-disabled-color: #{$greyLightBlue};
  --btn-no-style: #{$greyLightBlue};

  // inputs
  --today-active-color: #{$blue-dark};
  --holiday-bg-color: #{$greyDark};
  --presence-text-color: #{$apolloBlue};

  // change theme
  --icon-theme-switch: var(--primary-text-color);
  --big-button: #{$white};
  --slide-bar: #{$greyLightBlue};

  --absence-color: #{$lightRed};
}