.month {
  .calendar-container {
    .calendar {
      .day-names, .days {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 5px;
        gap: 5px;
      }

      .day-names {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
      }

      .days {
        background-color: var(--primary-bg-color);
        grid-auto-rows: max-content;
        border-radius: 5px;
      }
    }
  }
}