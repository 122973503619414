@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  
    #login-container-mobile{
        flex-direction: column;
        min-width: 0;
  
      #name-input-mobile{
        margin-top: 10px;
        border: none;
      }
    }
  }