@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
    .presences {
        .top-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .left{
                margin-bottom: 10px;
            }

            .right {
                display: grid;
                grid-auto-flow: column;
                gap: 10px;
                align-items: center;

                .type-visualisation-selector {
                    border-radius: 5px;
                    overflow: hidden;
                    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35);
                    display: flex;

                    .btn {
                        border-radius: 0;
                        width: 50px;
                    }
                }

                .btn-filters {
                    width: 50px;
                }
            }
        }
    }
}
