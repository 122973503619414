.btn {
  font-size: 14px;
  padding: 10px 8px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-bg-color);
  color: var(--primary-text-color);
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;

  &.btn-no-style {
    border: 0;
    padding: 0;
    margin: 0;
    background-color: inherit;
    text-transform: none;
    text-align: left;

    &:hover {
      box-shadow: none;
    }
  }

  &.btn-icon {
    min-width: 140px;
    display: inline-grid;
    justify-content: space-between;
    align-items: center;
    grid-auto-flow: column;
    gap: 10px;
  }
  &.full-width {
    width: 100%;
  }

  &:hover {
    box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &.loading {
    display: inline-flex;
    align-items: center;

    &::after {
      content: " ";
      border-radius: 25px;
      height: 10px;
      width: 10px;
      background: var(--inverse-text-color);
      display: inline-block;
      animation: animLoading 1s infinite;
      opacity: 0.7;
    }
  }

  &.btn-default {
    background: var(--btn-default-background);
    color: var(--btn-default-color);
    border: solid 1px var(--primary-border-color);
  }

  &.btn-primary {
    color: var(--inverse-text-color);
    background: var(--btn-primary-background);
  }
  &.btn-secondary {
    color: var(--btn-default-color);
    background: var(--btn-secondary-background);
  }
  &.btn-tertiary {
    background: var(--btn-tertiary-background);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35);
  }
  &.btn-danger {
    color: var(--inverse-text-color);
    background: var(--btn-danger-background);
  }
  &.btn-success {
    color: var(--inverse-text-color);
    background: var(--btn-success-background);
  }
  &.btn-disabled,
  &.disabled,
  &:disabled,
  &.loading {
    opacity: 0.4;
    pointer-events: none;
  }
}

@keyframes animLoading {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
}
