.presences {
  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .center {
      text-align: center;

      h1 {
        margin-bottom: 4px;
      }

      h2 {
        margin-bottom: 16px;
      }
    }

    .right {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      align-items: center;

      .type-visualisation-selector {
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35);

        .btn {
          border-radius: 0;
          width: 50px;
        }
      }

      .btn-filters {
        width: 50px;
      }
    }
  }
}