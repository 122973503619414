html,
body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Gilroy", sans-serif;
  color: var(--primary-text-color);
  background-color: var(--secondary-bg-color);
  font-size: 13px;
  transition-duration: .3s;
}

#root {
  height: 100%;
}

h1 {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 30px;
  margin: 0 0 20px 0;
}

h2 {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 22px;
  margin: 0 0 20px 0;
}

h3 {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 15px 0;
}

h4 {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 10px 0;
}

.container {
  width: 100%;
  max-width: 100vw;
  padding: 20px;
  box-sizing: border-box;
}
