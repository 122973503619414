.select-week {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .week {
    font-weight: bold;
    width: 220px;
  }
}
