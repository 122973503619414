.week-day {
  border-radius: 5px;
  background-color: var(--secondary-bg-color);

  &>div {
    padding: 10px;
  }

  &.holiday {
    background-color: var(--holiday-bg-color);
  }

  &.today {
    background-color: var(--today-active-color);
  }

  &.day-passed {
    opacity: 0.9;
  }
  
  >.day-passed {
    opacity: 0.6;
  }

  .title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--primary-border-color);
    margin-bottom: 4px;
  }
}