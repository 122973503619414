.month-day {
  background-color: var(--secondary-bg-color);
  border-radius: 5px;

  &.holiday {
    background-color: var(--holiday-bg-color);
  }

  &.today {
    background-color: var(--today-active-color);
  }

  &.day-passed {
    background-color: var(--day-passed-bg-color);
  }

  .date {
    font-size: 18px;
    padding: 5px;
  }

  .users {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 10px;

    .user-mini {
      $bubbleSize: 26px;
      font-size: 10px;
      font-weight: bold;
      cursor: default;

      .name {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: var(--presence-text-color);
      }

      .day {
        position: relative;
        display: flex;
        transform: rotate(90deg);

        .morning {
          border-top-left-radius:  $bubbleSize / 2;
          border-bottom-left-radius:  $bubbleSize / 2;
          width: $bubbleSize / 2;
          height: $bubbleSize;
        }

        .afternoon {
          border-top-right-radius:  $bubbleSize / 2;
          border-bottom-right-radius:  $bubbleSize / 2;
          width: $bubbleSize / 2;
          height: $bubbleSize;
        }

        .color-absent {
          background: var(--absence-color)
        }

        .color-present {
          background: var(--primary-color)
        }

        .color-default {
          background: var(--tertiary-bg-color)
        }


      }

      .user-tooltip {
        font-weight: normal;

        .full-name {
          font-weight: bold;
        }
      }
    }
  }
}